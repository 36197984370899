import { createStore } from 'vuex'
// import { $busEmit } from '@/plugins'

// Api modules
import main from '@/api/modules/main'
import users from '@/api/modules/users'
import projects from '@/api/modules/projects'

// Vuex modules
import helpers from './helpers'
import orders from './orders'
import notifications from './notifications'
import events from './events'

const defaultState = () => {
  return {
    timerID: -1,
    timer: 7200000,
    theme: 'light',
    prefix: 'choco',
    token: JSON.parse(localStorage.getItem('choco_token')) || null,
    auth: JSON.parse(localStorage.getItem('choco_auth')) || null,
    profile: JSON.parse(localStorage.getItem('choco_profile')) || null,
    user: null,
    users: [],
    projects: [],
    types: [],
    responsibles: []
  }
}

export default createStore({
  state: defaultState(),
  mutations: {
    CLEAR_STATE(state) {
      Object.assign(state, defaultState())
    },
    SET_TIMER(state, payload) {
      state.timer = payload
    },
    SET_TIMERID(state, payload) {
      state.timerID = payload
    },
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_AUTH(state, payload) {
      state.auth = payload
    },
    SET_PROFILE(state, payload) {
      state.profile = payload
    },
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_USERS(state, payload) {
      const users = []
      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        users.push({
          id: item.id,
          text: item.fullname,
          position: item.specialization || 'Нет должности',
          value: item.id,
          avatar: item.avatar
        })
      }
      state.users = users
    },
    SET_TYPES(state, payload) {
      const filtered = payload.filter((el) => el.value)
      state.types = filtered.map((el) => {
        return { id: el.id, text: el.title, value: el.value }
      })
    },
    SET_PROJECTS(state, payload) {
      const projects = []
      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        projects.push({
          id: item.id,
          text: item.project,
          value: item.id,
          item: { ...item }
        })
      }
      state.projects = projects
    },
    SET_RESPONSIBLES(state, payload) {
      const users = []
      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        users.push({
          id: item.id,
          text: item.fullname,
          position: item.specialization || 'Нет должности',
          value: item.id,
          avatar: item.avatar
        })
      }
      state.responsibles = users
    },
    SET_COUTERPARTIES(state, payload) {
      const counterparties = []
      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        counterparties.push({
          id: item.id,
          text: item.bin + ' - ' + item.name,
          value: item.bin,
          name: item.name,
          info: item.info
        })
      }
      state.counterparties = counterparties
    }
  },

  actions: {
    async GET_ME(_, access_token) {
      return await main.getMe(access_token)
    },
    GET_PROFILE({ getters, commit }) {
      return main.getProfile().then(({ data }) => {
        localStorage.setItem(`${getters.prefix}_profile`, JSON.stringify(data.data))
        commit('SET_PROFILE', data.data)
        localStorage.setItem('isSysAdmin', data.data.sysadmin_role)
      })
    },
    GET_USER({ commit }, id) {
      return (
        id &&
        main.getOtherProfile(id).then(({ data }) => {
          commit('SET_USER', data.data)
        })
      )
    },
    async LOGOUT() {
      return await main.logout()
    },
    GET_USERS({ commit }, payload) {
      if (payload) {
        return users
          .getAll(payload)
          .then(({ data }) => {
            commit('SET_USERS', data.data)
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        return new Promise((resolve) => resolve())
      }
    },
    GET_PROJECTS({ commit }, payload) {
      return projects
        .getAll(payload)
        .then(({ data }) => {
          commit('SET_PROJECTS', data.data)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    GET_RESPONSIBLES({ commit }, payload) {
      console.log(payload)
      if (payload) {
        return users
          .getAll(payload?.searchText, payload?.only)
          .then(({ data }) => {
            commit('SET_RESPONSIBLES', data.data)
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        return new Promise((resolve) => resolve())
      }
    },
    GET_TYPES({ commit }, payload) {
      return main
        .getTypes(payload)
        .then(({ data }) => {
          commit('SET_TYPES', data.data)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  },

  getters: {
    timerID: (s) => s.timerID,
    timer: (s) => s.timer,
    prefix: (s) => s.prefix,
    token: (s) => s.token,
    auth: (s) => s.auth,
    profile: (s) => s.profile,
    user: (s) => s.user,
    users: (s) => s.users,
    projects: (s) => s.projects,
    types: (s) => s.types,
    responsibles: (s) => s.responsibles
  },

  modules: {
    helpers,
    orders,
    events,
    notifications
  }
})
