import axios from '../axios'

class UsersApi {
  getAll(searchText = '', only = '') {
    const ser = searchText ? searchText : 'aaa'
    let url = '/api/search?fullname=' + ser
    if (only === 'sysadmin') url += '&only_sysadmin=1'
    return axios.get(url)
  }
  get(id) {
    return axios.get(`/api/lawyer-request/${id}`)
  }
}

export default new UsersApi()
