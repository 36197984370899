import { $busEmit } from '@/plugins'
import orders from '@/api/modules/orders'
import ordersCols from '@/assets/json/orders-cols'

export default {
  namespaced: true,
  state: () => ({
    rows: [],
    item: null,
    size: 50,
    page: 1,
    pageCount: 10,
    tableCols: localStorage.getItem('choco_cols') ? JSON.parse(localStorage.getItem('choco_cols')) : [...ordersCols],
    tableGrouped: '',
    tableSort: localStorage.getItem('choco_sort')
      ? JSON.parse(localStorage.getItem('choco_sort'))
      : {
          direction: 'desc',
          key: 'created_at'
        },
    filters: localStorage.getItem('choco_filters') ? JSON.parse(localStorage.getItem('choco_filters')) : {}
  }),
  mutations: {
    SET_TABLE_GROUPED_BY(state, payload) {
      localStorage.setItem('choco_grouped', payload)
      state.tableGrouped = payload
    },
    RESET_COLS(state) {
      localStorage.removeItem('choco_cols')
      state.tableCols = [...ordersCols]
    },
    SET_SIZE(state, payload) {
      state.size = payload
    },
    SET_PAGE(state, payload) {
      state.page = payload
    },
    SET_PAGE_COUNT(state, payload) {
      state.pageCount = payload
    },
    SET_COLS(state, payload) {
      localStorage.setItem('choco_cols', JSON.stringify(payload))
      state.tableCols = payload
    },
    SET_SORT(state, payload) {
      const res = {
        direction: state.tableSort.direction === 'asc' ? 'desc' : 'asc',
        key: payload
      }
      localStorage.setItem('choco_sort', JSON.stringify(res))
      state.tableSort = res
    },
    SET_FILTERS(state, { key, value }) {
      if (value) state.filters[key] = value
      else delete state.filters[key]
      localStorage.setItem('choco_filters', JSON.stringify(state.filters))
    },
    CLEAR_FILTERS(state) {
      state.filters = {}
      localStorage.setItem('choco_filters', JSON.stringify(state.filters))
    },
    SET_MORE_ROWS(state, payload) {
      state.rows = [...state.rows, ...payload]
    },
    SET_ROWS(state, payload) {
      state.rows = payload
    },
    SET_ITEM(state, payload) {
      state.item = payload
    },
    SET_OPTIONS(state, payload) {
      state.page = payload?.current_page
      state.size = payload?.per_page
      state.pageCount = payload?.last_page
    },
    ADD_ROW(state, payload) {
      let index = state.rows.findIndex((item) => item.id == payload.id)
      if (index === -1) {
        state.rows.unshift(payload)
      }
    },
    UPDATE_ROW(state, payload) {
      const index = state.rows.findIndex((i) => i.id == payload.id)
      if (index != -1) {
        state.rows[index] = payload
      }
    }
  },
  actions: {
    async GET_ROWS({ commit, state }) {
      try {
        const { data } = await orders.getAll(state.filters, state.tableSort, state.size, state.page)
        commit('SET_OPTIONS', data.meta)
        commit('SET_ROWS', data.data)
      } catch (e) {
        console.log(e)
        $busEmit('setToast', {
          type: 'red',
          icon: 'alert-triangle',
          title: 'Произошла ошибка',
          message: 'Не удалось получить список заявок, обратитесь к Администратору или перезагрузите страницу'
        })
      }
    },
    async GET_MORE_ROWS({ commit, state }) {
      try {
        const { data } = await orders.getAll(state.filters, state.tableSort, state.size, state.page + 1)
        commit('SET_OPTIONS', data.meta)
        commit('SET_PAGE', state.page)
        commit('SET_MORE_ROWS', data.data)
      } catch (e) {
        console.log(e)
        $busEmit('setToast', {
          type: 'red',
          icon: 'alert-triangle',
          title: e?.response?.status + ' ' + e?.response?.statusText || 'Произошла ошибка',
          message: 'Не удалось получить список заявок, обратитесь к Администратору или перезагрузите страницу'
        })
      }
    },
    async GET_ITEM({ commit }, id) {
      try {
        const { data } = await orders.get(id)
        commit('SET_ITEM', data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async ADD_NEW({ dispatch }, payload) {
      try {
        await orders.create(payload)
        dispatch('GET_ROWS')
      } catch (e) {
        console.log(e)
      }
    }
  },
  getters: {
    tableCols: (s) => s.tableCols,
    tableGrouped: (s) => s.tableGrouped,
    tableSort: (s) => s.tableSort,
    filters: (s) => s.filters,
    rows: (s) => s.rows,
    item: (s) => s.item,
    getSize: (s) => s.size,
    getPage: (s) => s.page,
    getPageCount: (s) => s.pageCount
  }
}
