import notificationsSettings from '@/api/modules/notifications-settings'

const defaultState = () => {
  return {
    count: '',
    rows: [],
    pages: null,
    settings: {
      changing_request: true,
      changing_status: true,
      is_email: true,
      is_telegram: true,
      time_reminder: true
    }
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    SET_ITEM(state, payload) {
      const index = state.rows.findIndex((item) => item.id === payload.id)
      if (index !== -1) state.rows.splice(index, 1, payload)
    },
    SET_SETTINGS(state, payload) {
      const res = {}
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          const element = payload[key]
          if (key !== 'id') res[key] = !!element
        }
      }
      state.settings = res
    }
  },
  actions: {
    GET_SETTINGS({ commit }) {
      return notificationsSettings.get().then(({ data }) => {
        commit('SET_SETTINGS', data.data)
      })
    },
    UPDATE_SETTINGS(_, { payload, isReset }) {
      const res = isReset ? defaultState().settings : payload
      const obj = {}
      for (const key in res) {
        if (Object.hasOwnProperty.call(res, key)) {
          const element = res[key]
          obj[key] = element ? 1 : 0
        }
      }
      return notificationsSettings.update(obj)
    }
  },
  getters: {
    settings: (s) => s.settings
  }
}
