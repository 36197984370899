import axios from '../axios'

class MainApi {
  signIn(params) {
    return axios.post('/oauth/token', params)
  }
  getMe(access_token) {
    return axios.get('/api/me', {
      headers: {
        Authorization: 'Bearer ' + access_token
      }
    })
  }
  getTypes() {
    return axios.get('/api/sysadmin/type')
  }
  getProfile() {
    return axios.get('/api/profile')
  }
  getOtherProfile(id) {
    return axios.get('/api/users/' + id)
  }
  logout() {
    return axios.get('/api/logout')
  }
}

export default new MainApi()
