import axios from '../axios'

class StatusesApi {
  getAll() {
    return axios.get('/api/sysadmin/status')
  }
  get(id) {
    return axios.get(`/api/sysadmin/status/${id}`)
  }
  create(payload) {
    return axios.post('/api/sysadmin/status', payload)
  }
  update(id, payload) {
    return axios.patch(`/api/sysadmin/status/${id}`, payload)
  }
  delete(id) {
    return axios.delete(`/api/sysadmin/status/${id}`)
  }
}

export default new StatusesApi()
