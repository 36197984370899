<template>
  <div class="grid ggap-30">
    <!-- Responsible -->
    <Responsible v-if="!isRead" :required="true" :only="only" v-model="propValueObjUsers.responsible" />

    <!-- Other people -->
    <div class="users grid ggap-10">
      <TabSwipe :items="tabs" v-model="currentTab">
        <template #item="{ item }">
          <div class="flex ai-c ggap-5">
            <span class="fs-20">{{ item.icon }}</span>
            {{ item.text }}
            <small v-if="item.count" class="marker" :class="currentTab === item.value ? 'primary' : 'grey'">
              {{ item.count }}
            </small>
          </div>
        </template>
      </TabSwipe>

      <!-- Свободный выбор -->
      <component :is="currentComponentFree" :currentTab="currentTab" v-model:users="propValueFreeUsers[currentTab]" />
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineEmits, defineProps, ref, defineAsyncComponent, computed, toRefs } from 'vue'
import { TabSwipe } from '@/components'
import Responsible from './Responsible.vue'

// Emits
const emits = defineEmits(['update:isFree', 'update:objUsers', 'update:freeUsers', 'update:departmentUsers'])

// Props
const props = defineProps({
  required: {
    type: Boolean,
    default: false
  },
  isRead: {
    type: Boolean,
    default: false
  },
  isFree: {
    type: Object,
    default: () => ({})
  },
  objUsers: {
    type: Object,
    default: () => ({
      responsible: {},
      final_boss: {}
    })
  },
  freeUsers: {
    type: Object,
    default: () => ({
      reviewers: [],
      agreementers: [],
      watchers: []
    })
  },
  only: {
    type: String,
    default: ''
  }
})

// Data
const { isRead, isFree, objUsers, freeUsers, only } = toRefs(props)
const store = useStore()
const currentTab = ref('reviewers')
const tabs = ref([
  {
    icon: '💬',
    text: 'Участники',
    value: 'reviewers',
    count: computed(() => freeUsers.value?.reviewers?.length)
  }
])

// Computed:components

const currentComponentFree = defineAsyncComponent(() => {
  return isRead.value ? import('./free/View.vue') : import('./free/Edit.vue')
})

// Computed:modelValue
const propValueIsFree = computed({
  get: () => isFree.value,
  set: (val) => emits('update:isFree', val)
})
const propValueObjUsers = computed({
  get: () => objUsers.value,
  set: (val) => emits('update:objUsers', val)
})
const propValueFreeUsers = computed({
  get: () => freeUsers.value,
  set: (val) => emits('update:freeUsers', val)
})

// Created
setSwitchState()
store.dispatch('GET_USERS')

// Methods
function setSwitchState() {
  propValueIsFree.value.reviewers = !!freeUsers.value.reviewers.length
}
</script>
