<template>
  <header class="head grid">
    <div class="content flex ai-c ggap-20">
      <div class="flex ai-c ggap-10">
        <a href="https://intra.chocofamily.kz" class="head__logo pt-10 pb-10 flex ai-c">
          <img src="@/assets/img/logo.svg" />
        </a>

        <div class="select-app">
          <BaseDropDown btn-class="btn apps fw-b pl-10 pr-10">
            <template #btn>
              <BaseIcon class="ic-18 white" icon="apps" />
            </template>
            <template #body>
              <div class="grid p-5">
                <a href="https://intra.chocofamily.kz" class="btn transparent-grey md fw-b br-5">
                  <BaseIcon class="ic-16 primary" icon="home" />
                  Интра
                </a>
                <a href="https://soc.chocofamily.kz" class="btn transparent-grey md fw-b br-5">
                  <BaseIcon class="ic-16 primary" icon="users" />
                  Социальная сеть
                </a>
                <a href="https://law.chocofamily.kz" class="btn transparent-grey md fw-b br-5">
                  <BaseIcon class="ic-16 primary" icon="doc-1" />
                  Юристы
                </a>
                <a href="https://payments.chocofamily.kz" class="btn transparent-grey md fw-b br-5">
                  <BaseIcon class="ic-20 primary" icon="kzt" />
                  Финансисты
                </a>
                <a href="https://sysadmin.chocofamily.kz" class="btn primary md fw-b br-5" @click.prevent>
                  <BaseIcon class="ic-20 white" icon="help" />
                  Сисадмины
                </a>
              </div>
            </template>
          </BaseDropDown>
        </div>
      </div>

      <Search />

      <nav class="head__nav flex ai-c ggap-10" v-if="isSysAdmin">
        <RouterLink to="/orders" class="head__nav-item btn primary-head" active-class="active">
          <BaseIcon class="ic-16 white" icon="doc-2" />
          Заявки
        </RouterLink>
        <RouterLink to="/settings" class="head__nav-item btn primary-head" active-class="active">
          <BaseIcon class="ic-16 white" icon="settings" />
          Настройки
        </RouterLink>
      </nav>

      <div class="head__right ml-auto flex ai-c ggap-10">
        <RouterLink class="btn secondary" to="/orders/add" append>
          <BaseIcon class="ic-16 white" icon="plus" />
          Новая заявка
        </RouterLink>

        <SwitchTheme />

        <User />
      </div>
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Search from './Search.vue'
import SwitchTheme from './SwitchTheme.vue'
import User from './User.vue'
import { BaseIcon, BaseDropDown } from '@/components'

const store = useStore()
const profile = computed(() => store.getters.profile)
const isSysAdmin = computed(
  () => profile.value?.sysadmin_role === 'Sysadmin' || [1510, 1312].includes(profile.value?.id)
)
</script>

<style lang="scss" scoped>
.head {
  background-color: var(--head);
  height: 50px;

  &__logo {
    img {
      width: auto;
      height: 18px;
    }
  }

  &__nav {
    &-item {
      text-decoration: none;
      color: #fff;
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }
  }
}
</style>
